import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(cardAnatomy.keys);

// This card padding was changed as part of a tweaking session before launch. We did so knowing that it would cause there to be a different
// amount of padding between the header and the body, and the footer and the body.
// If changing this in the future, please check to see if the thread listing view still looks good.
const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    padding: 8,
    color: 'text.body'
  },
  header: {
    paddingTop: 0,
    paddingBottom: 2,
    paddingLeft: 0,
    paddingRight: 0
  },
  body: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0
  },
  footer: {
    paddingTop: 6,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
});
const variants = {
  simple: props => {
    return definePartsStyle({
      container: {
        py: 5,
        borderRadius: 0,
        borderWidth: 0,
        boxShadow: 'none'
      },
      header: Object.assign({}, props.theme.components.Card.baseStyle.header, {
        pb: 0
      }),
      footer: Object.assign({}, props.theme.components.Card.baseStyle.footer)
    });
  },
  compact: props => {
    return definePartsStyle({
      container: Object.assign({}, props.theme.components.Card.variants.elevated.container, {
        paddingY: 6
      }),
      header: Object.assign({}, props.theme.components.Card.baseStyle.header),
      footer: Object.assign({}, props.theme.components.Card.baseStyle.footer)
    });
  },
  pop: props => {
    return definePartsStyle({
      container: Object.assign({}, props.theme.components.Card.variants.elevated.container, {
        paddingY: 0,
        paddingX: 0
      }),
      header: Object.assign({}, props.theme.components.Card.baseStyle.header, {
        marginTop: 0,
        paddingY: 4
      }),
      footer: Object.assign({}, props.theme.components.Card.baseStyle.footer)
    });
  },
  community: props => {
    return definePartsStyle({
      container: Object.assign({}, props.theme.components.Card.variants.elevated.container, {
        padding: 0,
        direction: 'column',
        overflow: 'hidden'
      }),
      body: Object.assign({}, props.theme.components.Card.baseStyle.body, {
        paddingX: 5,
        paddingY: 5,
        marginTop: 0
      }),
      footer: Object.assign({}, props.theme.components.Card.baseStyle.footer, {
        paddingX: 4,
        paddingTop: 2
      })
    });
  }
};
export const cardTheme = defineMultiStyleConfig({
  baseStyle,
  variants
});
export default cardTheme;